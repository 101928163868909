<template>
   <el-container class="recordadd ">
       <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>内部管理</el-breadcrumb-item>
       <el-breadcrumb-item>从业人员管理</el-breadcrumb-item>
    </el-breadcrumb>
      </el-header>
      <el-main class="p15 pt0">
       <el-form :inline="true"  status-icon ref="page"  :model="page"  class="demo-ruleForm">    
      <el-row class="box mt20  f14 people-message">
       <div class="box list">
      <el-form ref="form" :model="form" >
       <el-table  ref="table" :data="form.List" style="width:100%" empty-text='暂无数据'>
          <el-table-column label="姓名">
           <template slot-scope="scope">
            <el-form-item size="medium" :prop="'List.' + scope.$index + '.name'" :rules="[{required: true, message: '姓名信息为空,需重新登记', trigger: 'blur' }]">
              <el-input placeholder="请输入姓名" clearable  v-model="scope.row.name"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="联系电话">
           <template slot-scope="scope">
            <el-form-item size="medium" >
              <el-input placeholder="请输入联系电话" clearable  v-model="scope.row.mobilephone"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
         <el-table-column label="健康证" width="180">
          <el-upload class="health_url"
                ref="upload"
                multiple
                v-if="imageUrl=='1234567'"
                fit="fill" style="width: 70px;height: 40px"
                action="/pc/file/upload"
                 accept="image/png,image/jpg,image/jpeg"
                 :limit="1"
                  list-type="picture-card"
                :on-success="handlepoll11"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove11"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled11}"
                >
       <el-button size="medium" type="primary">点击上传</el-button>
</el-upload>
<el-upload class="health_url"
                ref="upload"
                multiple
                v-else
                fit="fill" style="width: 70px;height: 40px"
                action=""
                 accept="image/png,image/jpg,image/jpeg"
                 :limit="1"
                  list-type="picture-card"
                :http-request="(params) =>{return handlepoll11(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove11"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled11}"
                >
       <el-button size="medium" type="primary">点击上传</el-button>
</el-upload>
        </el-table-column>
        
         <el-table-column label="健康证到期时间">
          <template slot-scope="scope">
            <el-form-item   size="medium" :prop="'List.' + scope.$index + '.time'" :rules="[{required: true, message: '到期时间为空,需重新登记', trigger: 'blur' }]">
          <el-date-picker type="date" clearable placeholder="选择到期时间" v-model="scope.row.time"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" ></el-date-picker>
        </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="密切接触冷链食品" >
                 <template slot-scope="scope">
                  <el-switch active-text="是" inactive-text="否" active-value='1' inactive-value='2' v-model="scope.row.state" active-color="#409EFF" inactive-color="#ccc" > </el-switch>
               </template>
          </el-table-column>
        <el-table-column  label="操作" >
          <template slot-scope="">
             <el-button  class="" size="medium" type="primary" @click="savepeople">保存</el-button>
          <el-button  class="back_btn"  size="medium" plain @click="cancle">清除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
      <el-table :data="list" style="width: 100%" class="no-header">
        <el-table-column   prop="company_employ_name" >
             <template slot-scope="scope">
                            <span v-if="scope.row.employ_flag">
                                <el-input size="mini" placeholder="请输入内容" v-model="master_user.sel.company_employ_name">
                                </el-input>
                            </span>
                            <span v-else>{{scope.row.company_employ_name}}</span>
                        </template>
        </el-table-column>
        <el-table-column   prop="mobilephone" >
             <template slot-scope="scope">
                            <span v-if="scope.row.employ_flag">
                                <el-input size="mini" placeholder="请输入内容" v-model="master_user.sel.mobilephone">
                                </el-input>
                            </span>
                            <span v-else>{{scope.row.mobilephone}}</span>
                        </template>
        </el-table-column>
        <el-table-column width="180">
             <template slot-scope="scope">
                 <el-upload v-if="scope.row.employ_flag&&imageUrl=='1234567'" class="health_url"
                ref="upload"
                :file-list="fileList"  
                fit="fill" style="width: 70px;height: 40px"
                action="/pc/file/upload"
                 accept="image/png,image/jpg,image/jpeg"
                 :limit="1"
                  list-type="picture-card"
                :on-success="handlepoll11"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove11"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled11}"
                >
       <el-button size="medium" type="primary">点击上传</el-button>
</el-upload>
<el-upload v-else-if="scope.row.employ_flag&&imageUrl!='1234567'" class="health_url"
                ref="upload"
                :file-list="fileList"  
                fit="fill" style="width: 70px;height: 40px"
                action="/pc/file/upload"
                 accept="image/png,image/jpg,image/jpeg"
                 :limit="1"
                  list-type="picture-card"
                :http-request="(params) =>{return handlepoll11(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove11"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled11}"
                >
       <el-button size="medium" type="primary">点击上传</el-button>
</el-upload>
           <el-image v-else-if="!scope.row.employ_flag&&scope.row.employ_health_url" :src="scope.row.employ_health_url" fit="fill" :preview-src-list="[scope.row.employ_health_url]"  style="width: 40px;height: 40px"></el-image>
            <img v-else-if="!scope.row.employ_flag&&scope.row.employ_health_url==''" src="../../../assets/image/moren.png" style="width: 40px;height: 40px" />
             </template>
        </el-table-column>
        <el-table-column prop="employ_health_datedue" >
              <template slot-scope="scope">
            <el-form-item  v-if="scope.row.employ_flag"  size="medium" >
          <el-date-picker type="date" clearable placeholder="选择有效期至" v-model="master_user.sel.employ_health_datedue"   format="yyyy-MM-dd" value-format="yyyy-MM-dd" ></el-date-picker>
        </el-form-item>
        <span v-else>{{scope.row.employ_health_datedue}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cold_chain_personnel">
               <template slot-scope="scope">
                  <el-switch  v-if="scope.row.employ_flag" active-text="是" inactive-text="否" active-value='1' inactive-value='2' v-model="master_user.sel.cold_chain_personnel" active-color="#409EFF" inactive-color="#ccc" > </el-switch>
                 <span v-else>
                   <span v-if="scope.row.cold_chain_personnel=='1'">是</span>
                   <span v-else-if="scope.row.cold_chain_personnel=='2'">否</span>
                 </span>
                </template>
             </el-table-column>
        <el-table-column fixed="right">
          <template slot-scope="scope">
              <el-button v-if="scope.row.employ_flag" class=""  size="medium" type="primary"  @click="handleUpdate(scope.row,scope.$index)">保存</el-button>
            <el-button v-else class=""  size="medium" type="primary"  @click="handleEdit(scope.row,scope.$index,true)">编辑</el-button>       
           <el-button  class="back_btn"  size="medium" plain  @click="handleDelete(scope.$index, scope.row.company_employ_id)">删除</el-button>
         </template>
        </el-table-column>
      </el-table>

    </div> 
     </el-row>

     </el-form> 
</el-main>
   </el-container>
</template>
<script>
import Vue from 'vue'
import axios from '../../../util/api'
import Globle from '@/components/Globle'
import { local,objKeySort } from '../../../util/util'
import {imageUrl} from '../../../config/index'
import { tengxuncloud } from '@/util/aliOss'
let defaultDate;
export default {
  data () {
    return {
      imageUrl:imageUrl,
      menu_btns:Globle.menu_btns,
      uploadDisabled11: false,
      list:[],
      fileList:[],
      page: {
        company_id:'',
        company_employ_id:'',
         company_employ_name:'',
         mobilephone:'',
         cold_chain_personnel:'',
        employ_health_url:'',
        employ_health_datedue: '',
        employ_health:'',
        note:''
      },
      user:'',
     form: {List: [{name: '',mobilephone:'',healthimg:'',time:'',state:''}]},
       dialogImageUrl: '',
        dialogVisible: false,
        pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      master_user: {
                    sel: null,//选中行   
                },
    }
  },
   created () {
      this.user = local.get('user');
      if(this.user.user_type!=1){
       this.page.company_id=local.get('company_id')?local.get('company_id').toString():'';
      }
       
    this.init()
  },
  methods: {
    //获取已有人员列表
    init () {
      axios.get('/pc/company-employ/all', {company_id:this.page.company_id}).then((v) => {
           this.list= v.data.map(item => {
                return {...item, employ_flag: false}
              })
      })
    },
    cancle(){ this.form.List=[{name: '',mobilephone:'',healthimg:'',time:'',state:''}]},
    //新增人员
     savepeople(form){
       this.$refs.form.validate(variable => {
         if (variable) {
           this.page.employ_health_url=this.form.List[0].healthimg.substring(this.form.List[0].healthimg.indexOf("resources")),
             this.page.company_employ_name=this.form.List[0].name,
             this.page.employ_health_datedue=this.form.List[0].time,
              this.page.mobilephone=this.form.List[0].mobilephone,
              this.page.cold_chain_personnel=this.form.List[0].state
           this.form.List=[{name: '',mobilephone:'',healthimg:'',time:'',state:''}]
           this.handleRemove11()
           this.$refs.upload.clearFiles();
           axios.post('/pc/company-employ/save', this.page).then((v) => {
          this.init()
          }).catch(() => {})
          }else{return}
        })
       },
       handleEdit(row, index, cg) {
                     if(row.employ_health_url!=''){this.form.List[0].healthimg=row.employ_health_url;this.fileList=[{url:row.employ_health_url}];this.uploadDisabled11=true}
                     this.master_user.sel = JSON.parse(JSON.stringify(row));
                        row.employ_flag = true;
                },
                //编辑人员
                handleUpdate(row, index){
        this.page.company_employ_id=row.company_employ_id;
         this.page.company_employ_name=this.master_user.sel.company_employ_name;
         this.page.mobilephone=this.master_user.sel.mobilephone;
        this.page.employ_health_url=this.form.List[0].healthimg;
        this.page.cold_chain_personnel=this.master_user.sel.cold_chain_personnel
        this.page.employ_health_datedue=this.master_user.sel.employ_health_datedue;
  axios.put('/pc/company-employ/update', this.page).then((v) => {
         this.init()
          }).catch(() => {})
                },
         handleDelete(index,val){ //删除行数
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
             axios.delete('/pc/company-employ/delete', {company_employ_id:String(val)}).then((v) => {

         this.init()
          }).catch(() => {})

        }).catch(() => {        
        });
           
        },
      handleRemove11(file, fileList) {
          this.uploadDisabled11 = false;
          this.form.List[0].healthimg=''
      },
      handlepoll11 (response, file, fileList) {
         this.uploadDisabled11 = true;
          if(this.imageUrl=='1234567'){
           this.form.List[0].healthimg=response.data.visit_url
          }else{
           tengxuncloud(response).then((res) => {
           this.form.List[0].healthimg= res.url
           })
          }
          
    },
      beforeRemove(file, fileList){
      
      if (file && file.status==="success") {
         return this.$confirm(`确定移除健康证吗？`);
      }
    },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
     
  }
}
</script>
<style lang="scss" scoped>
.recordadd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
       background: #2EC68A;
    border-color: #2EC68A;
    color: #FFFFFF;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
   /deep/ .el-table.no-header .el-table__header{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload{width: 160px;height: 160px;float: left;margin-right: 10px;
  .disabled{height: 100%;}
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}
    /deep/ .el-upload-list--picture-card .el-upload-list__item{width: 160px;height: 160px;}
   }
    .health_url{
     /deep/ .el-upload--picture-card{width: 70px;height: 40px;
     background-color: transparent;
    border: none;
     .el-button--medium{position: absolute;left: 10px;margin-top: 4px;}}
/deep/ .el-upload-list--picture-card .el-upload-list__item{width: 70px;height: 40px;}
   } 
    .cell .is-error{padding-bottom: 20px}
   /deep/ .disabled .el-upload--picture-card {
    display: none !important;
}
  .el-image{width: 160px; height: 160px;margin-right: 10px}

.el-checkbox-button{margin-bottom: 10px}
}

</style>